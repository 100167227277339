<template>
  <div class="navigationa">
    <!-- <div class="navigationa-top">
      <div class="title1">{{title1}}</div>
      <div class="title2">信息化技术服务平台</div>
      <a-button
        @click="
          () => {
            this.$router.go(-1);
          }
        "
        style="position:fixed;right:50px;top:50px"
        ><a-icon type="logout" size="large" />退出登陆</a-button
      >
      <div class="navigationa-top-con">
        <div class="navigationa-top-conone" @click="goToHy(2)">
          <div class="text">行业管理</div>
          <div class="icon">
            <img src="../../assets/img/public/hangyeguanli.png" alt="" />
          </div>
        </div>
        <div class="navigationa-top-conone" @click="goToHy(3)">
          <div class="text">水质管理</div>
          <div class="icon">
            <img src="../../assets/img/public/shuizhiguanli.png" alt="" />
          </div>
        </div>
        <div class="navigationa-top-conone" @click="goToHy(6)">
          <div class="text">水厂监测</div>
          <div class="icon">
            <img src="../../assets/img/public/shuichangjiankong.png" alt="" />
          </div>
        </div>
        <div class="navigationa-top-conone" @click="goToHy(5)">
          <div class="text">技术培训</div>
          <div class="icon">
            <img src="../../assets/img/public/yuanchengpeixun.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="navigationa-ud">
      <div class="navigationa-ud-con">
        <div class="navigationa-ud-con-left">
          <div class="navigationa-ud-conone">
            <img src="../../assets/img/public/renwuxiafa.png" alt="" />
            <div>任务下发</div>
          </div>
          <div class="navigationa-ud-conone" @click="goToHy(4)">
            <img src="../../assets/img/public/shujutianbao.png" alt="" />
            <div>数据填报</div>
          </div>
        </div>
        <div class="navigationa-ud-conone" @click="open()">
          <img src="../../assets/img/public/shuzishapan.png" alt="" />
          <div>数字沙盘</div>
        </div>
        <div class="navigationa-ud-con-right">
          <div class="navigationa-ud-conone">
            <img src="../../assets/img/public/gerenzhongxin.png" alt="" />
            <div>个人中心</div>
          </div>
          <div class="navigationa-ud-conone" @click="goToHy(10)">
            <img src="../../assets/img/public/yonghuguanli.png" alt="" />
            <div>用户管理</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="navigationa-top flex align-center justify-between">
      <div class="navigationa-top-left">
        {{ dates.slice(12) }}
      </div>
      <div class="navigationa-top-right">
        <a-dropdown>
          <a
            class="ant-dropdown-link"
            style="color:#ffffff"
            @click="e => e.preventDefault()"
          >
            {{ userName }} <a-icon type="down" style="font-size:2.5vw" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item>
              <a href="javascript:;" @click="goout()">退出登陆</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <div class="navigationa-day">{{ dates.slice(0, 10) }} {{ weeks }}</div>
    <div class="navigationa-con flex align-center justify-between">
      <div class="navigationa-con-left">
        <div class="navigationa-con-left-top flex align-end cursor-pointer" @click="open()" :class="[ canEnter(0) ? '' : 'img-gray']">
          <div class="navigationa-con-left-top-text">{{ title1 }}数字沙盘</div>
        </div>
        <div
          class="navigationa-con-left-bottom flex align-center justify-around "
        >
          <div class="navigationa-con-left-bottom-item cursor-pointer"  @click="goToHy(1)" :class="[ canEnter(1) ? '' : 'text-gray']">
            <img
              src="../../assets/img/public/imgF.png"
              class="navigationa-con-left-bottom-item-img"
            />
            <div class="navigationa-con-left-bottom-item-text">任务下发</div>
          </div>
          <img
            src="../../assets/img/public/img_shu.png"
            class="navigationa-con-left-bottom-item-shu"
          />
          <div class="navigationa-con-left-bottom-item cursor-pointer" @click="goToHy(4)" :class="[ canEnter(4) ? '' : 'text-gray']">
            <img
              src="../../assets/img/public/imgG.png"
              class="navigationa-con-left-bottom-item-img"
            />
            <div class="navigationa-con-left-bottom-item-text">数据填报</div>
          </div>
          <img
            src="../../assets/img/public/img_shu.png"
            class="navigationa-con-left-bottom-item-shu"
          />
          <div class="navigationa-con-left-bottom-item cursor-pointer" @click="goToHy(10)" :class="[ canEnter(10) ? '' : 'text-gray']">
            <img
              src="../../assets/img/public/imgH.png"
              class="navigationa-con-left-bottom-item-img"
            />
            <div class="navigationa-con-left-bottom-item-text">用户管理</div>
          </div>
        </div>
      </div>
      <div class="navigationa-con-listA cursor-pointer" @click="goToHy(2)" :class="[ canEnter(2) ? '' : 'img-gray']">
        <div class="navigationa-con-list flex align-center justify-between">
          <img
            src="../../assets/img/public/imgI.png"
            class="navigationa-con-list-img"
          />
          <div class="navigationa-con-list-text">
            行业管理
          </div>
        </div>
        <div class="navigationa-con-list-con flex align-center justify-center">
          <img
            src="../../assets/img/public/imgM.png"
            class="navigationa-con-list-con-img"
          />
        </div>
      </div>
      <div class="navigationa-con-listB cursor-pointer" @click="goToHy(3)" :class="[ canEnter(3) ? '' : 'img-gray']">
        <div class="navigationa-con-list flex align-center justify-between">
          <img
            src="../../assets/img/public/imgJ.png"
            class="navigationa-con-list-img"
          />
          <div class="navigationa-con-list-text">
            水质管理
          </div>
        </div>
        <div class="navigationa-con-list-con flex align-center justify-center">
          <img
            src="../../assets/img/public/imgN.png"
            class="navigationa-con-list-con-img"
          />
        </div>
      </div>
      <div class="navigationa-con-listC">
        <div class="navigationa-con-listC-top cursor-pointer" @click="goToHy(6)" :class="[ canEnter(6) ? '' : 'img-gray']">
          <!-- <div class="navigationa-con-listC-top img_gray"> -->
          <div
            class="navigationa-con-listC-topflex flex align-center justify-between"
          >
            <img
              src="../../assets/img/public/imgK.png"
              class="navigationa-con-list-img"
            />
            <div class="navigationa-con-list-text">
              水厂监测
            </div>
          </div>
          <div
            class="navigationa-con-listC-con flex align-center justify-center"
          >
            <img
              src="../../assets/img/public/imgO.png"
              class="navigationa-con-list-con-imgB"
            />
          </div>
        </div>
        <div class="navigationa-con-listC-bottom cursor-pointer" @click="goToHy(5)" :class="[ canEnter(5) ? '' : 'img-gray']">
          <div
            class="navigationa-con-listC-topflex flex align-center justify-between"
          >
            <img
              src="../../assets/img/public/imgL.png"
              class="navigationa-con-list-img"
            />
            <div class="navigationa-con-list-text">
              技术培训
            </div>
          </div>
          <div
            class="navigationa-con-listC-con flex align-center justify-center"
          >
            <img
              src="../../assets/img/public/imgP.png"
              class="navigationa-con-list-con-imgB"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="navigationa-name">{{ title1 }}信息化技术服务平台</div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "navigationa",
  data() {
    return {
      userName: "",
      dates: "2021年00月00 00:00",
      weeks: "星期",
      routers: [], //总路由
      useRouter: [], //显示侧导航路由
      menus: [],
      jurisdiction: {
        menu_name: "权限管理",
        dbId: 10086,
        icon: "bg-colors",
        component: "",
        children: [
          {
            menu_name: "用户管理",
            dbId: 1008611,
            icon: "user",
            component: "",
            children: [
              {
                menu_name: "用户列表",
                dbId: 100861101,
                icon: "",
                component: "/index/auth/users/usersList"
              },
              {
                menu_name: "新增用户",
                dbId: 100861102,
                icon: "",
                component: "/index/auth/users/createUsers"
              }
            ]
          },
          {
            menu_name: "角色管理",
            dbId: 1008612,
            icon: "gold",
            component: "",
            children: [
              {
                menu_name: "角色列表",
                dbId: 100861201,
                icon: "",
                component: "/index/auth/role/roleList"
              },
              {
                menu_name: "新增角色",
                dbId: 100861202,
                icon: "",
                component: "/index/auth/role/createRole"
              }
            ]
          },
          {
            menu_name: "组织管理",
            dbId: 1008613,
            icon: "bank",
            component: "",
            children: [
              {
                menu_name: "组织列表",
                dbId: 100861301,
                icon: "",
                component: "/index/auth/organization/organizationList"
              },
              {
                menu_name: "新增组织",
                dbId: 100861302,
                icon: "",
                component: "/index/auth/organization/createOrganization"
              }
            ]
          },
          {
            menu_name: "菜单管理",
            dbId: 1008614,
            icon: "unordered-list",
            component: "",
            children: [
              {
                menu_name: "菜单管理",
                dbId: 100861401,
                icon: "",
                component: "/index/auth/menu/menuManagement"
              },
              {
                menu_name: "修改系统名称",
                dbId: 100861402,
                icon: "",
                component: "/index/auth/menu/revise"
              }
            ]
          }
        ]
      },
      title1: "农村供水",
      bigScreenUrl: "https://waterss.ez-info.cn/shanxi/sx.html"
    };
  },
  created() {
    this.userName = localStorage.getItem("userName");
    setInterval(() => {
      setTimeout(() => {
        this.dates = this.getNowTime();
        this.weeks = this.getWeekDate();
      }, 0);
    }, 1000);
    const host = window.location.host;
    if (host.indexOf("shanxi") !== -1) {
      this.title1 = "山西农村供水";
      this.bigScreenUrl = "https://waterss.ez-info.cn/shanxi/sx.html";
    } else if (host.indexOf("liaoning") !== -1) {
      this.title1 = "辽宁农村供水";
      this.bigScreenUrl = "https://waterss.ez-info.cn/shanxi/ln.html";
    } else if (host.indexOf("spt") !== -1) {
      this.title1 = "沙坡头农村供水";
      this.bigScreenUrl = "https://waterss.ez-info.cn/shanxi/sx.html";
    } else {
      this.title1 = "农村供水";
    }
    // this.menus = JSON.parse(localStorage.getItem("menus"));
    // this.menus.push(this.jurisdiction);
    // localStorage.setItem("menus", JSON.stringify(this.menus));
    this.routers = this.$router.options.routes;
    this.menus = JSON.parse(localStorage.getItem("menus"));
    console.log("menus", this.menus);
  },
  methods: {
    canEnter(index){
      if(index === 0){
        return localStorage.getItem('userLevel') == 1
      }
      let length = this.menus.length
      for (let i = 0; i < length; i++) {
        if(this.menus[i].menu_name == this.getMenuName(index)){
          return true
        }
      }
      return false
    },
    getMenuName(index){
      let name = ''
      switch (index) {
        case 1:
          name = '任务下发'
          break
        case 2:
          name = '行业管理'
          break
        case 3:
          name = '水质管理'
          break
        case 4:
          name = '数据填报'
          break
        case 5:
          name = '技术培训'
          break
        case 6:
          name = '水厂监测'
          break
        case 10:
          name = '用户管理'
          break
      }
      return name
    },
    goout() {
      localStorage.removeItem("token");
      localStorage.removeItem("admin");
      localStorage.removeItem("userName");
      this.$router.push({
        name: "login"
      });
    },
    getWeekDate() {
      var now = new Date();
      var day = now.getDay();
      var weeks = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      );
      var week = weeks[day];
      return week;
    },
    //获取当前时间
    getNowTime() {
      var date = new Date();
      //年 getFullYear()：四位数字返回年份
      var year = date.getFullYear(); //getFullYear()代替getYear()
      //月 getMonth()：0 ~ 11
      var month = date.getMonth() + 1;
      //日 getDate()：(1 ~ 31)
      var day = date.getDate();
      //时 getHours()：(0 ~ 23)
      var hour = date.getHours();
      //分 getMinutes()： (0 ~ 59)
      var minute = date.getMinutes();
      //秒 getSeconds()：(0 ~ 59)
      var second = date.getSeconds();

      var time =
        year +
        "年" +
        this.addZero(month) +
        "月" +
        this.addZero(day) +
        "日 " +
        this.addZero(hour) +
        ":" +
        this.addZero(minute);
      return time;
    },

    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? "0" + s : s;
    },

    open() {
      if(this.canEnter(0)){
        window.open(this.bigScreenUrl);
      }
    },
    goToHy(index) {
      let nav = "";
      let name = this.getMenuName(index)
      for (let i in this.menus) {
        if(this.menus[i].menu_name == name){
          nav = i
        }
        // if (this.menus[i].menu_name == "行业管理" && index == 2) {
        //   nav = i;
        // }
        // if (this.menus[i].menu_name == "水质管理" && index == 3) {
        //   nav = i;
        // }
        // if (this.menus[i].menu_name == "技术培训" && index == 5) {
        //   nav = i;
        // }
        // if (this.menus[i].menu_name == "数据填报" && index == 4) {
        //   nav = i;
        // }
        // if (this.menus[i].menu_name == "用户管理" && index == 10) {
        //   nav = i;
        // }
        // if (this.menus[i].menu_name == "水厂监测" && index == 6) {
        //   nav = i;
        // }
      }
      console.log("goToHy", nav, this.menus);
      if (nav != "") {
        this.useRouter = this.menus[nav];
        this.$store.commit("setRouters", this.useRouter);
        localStorage.setItem("nav", nav);
        let routers = JSON.stringify(this.useRouter);
        localStorage.setItem("routers", routers);
        let path = this.useRouter.children[0].component
          ? this.useRouter.children[0].component
          : this.useRouter.children[0].children[0].component;
        if (
          this.useRouter.children.length > 0 &&
          this.useRouter.children[0].component
        ) {
          this.$store.commit("setNavNum", this.useRouter.children[0].dbId);
        } else {
          this.$store.commit(
            "setNavNum",
            this.useRouter.children[0].children[0].dbId
          );
        }
        this.$router.push({ path: path });
      }
      // else {
      //   this.$message.error("暂无权限访问，请联系管理员");
      // }
    }
  }
};
</script>

<style scoped lang="less">
.navigationa {
  width: 100%;
  height: 100vh;
  background: url(../../assets/img/public/bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  // .navigationa-top {
  //   width: 80%;
  //   height: 67%;
  //   margin: 0 auto;
  //   .title1 {
  //     font-size: 60px;
  //     font-weight: bold;
  //     color: #ffffff;
  //     text-align: center;
  //     padding-top: 5vh;
  //   }
  //   .title2 {
  //     font-size: 40px;
  //     font-weight: 400;
  //     color: #ffffff;
  //     text-align: center;
  //     padding-top: 1vh;
  //   }
  //   .navigationa-top-con {
  //     display: flex;
  //     justify-content: space-between;
  //     margin-top: 7vh;
  //     .navigationa-top-conone {
  //       cursor: pointer;
  //       width: 200px;
  //       height: 200px;
  //       background: #ffffff;
  //       box-shadow: 3px 3px 15px 0px rgba(10, 66, 79, 0.2);
  //       border-radius: 10px;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;

  //       .text {
  //         font-size: 24px;
  //         font-weight: 400;
  //         color: #28282c;
  //         padding-top: 30px;
  //       }
  //       .icon {
  //         margin-top: 20px;
  //         img {
  //           width: 60px;
  //           height: 60px;
  //         }
  //       }
  //     }
  //   }
  // }
  // .navigationa-ud {
  //   width: 100%;
  //   height: 33%;
  //   background: rgba(255, 255, 255, 0.3);
  //   .navigationa-ud-con {
  //     width: 60%;
  //     height: 100%;
  //     padding: 5vh 0px 0px;
  //     box-sizing: border-box;
  //     margin: 0 auto;
  //     display: flex;
  //     justify-content: space-between;
  //     align-items: center;
  //     .navigationa-ud-conone {
  //       width: 18%;
  //       min-width: 160px;
  //       height: 80px;
  //       background: #ffffff;
  //       border-radius: 10px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       cursor: pointer;
  //       img {
  //         width: 28px;
  //         height: 28px;
  //       }
  //       div {
  //         font-size: 24px;
  //         color: #28282c;
  //         margin-left: 4px;
  //       }
  //     }
  //   }
  // }
}
// .navigationa-ud-con-left {
//   width: 200px;
//   height: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: space-between;
// }
// .navigationa-ud-con-right {
//   width: 200px;
//   height: 100%;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: space-between;
// }
.navigationa-top {
  width: 100%;
  padding: 2.87vh 3.073vw 0px 3.542vw;
  box-sizing: border-box;
}
.navigationa-top-left {
  font-family: ArialMT;
  font-size: 6.25vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 6.25vw;
  letter-spacing: 1px;
  color: #ffffff;
  opacity: 0.98;
}
.navigationa-top-right {
  font-family: AdobeHeitiStd-Regular;
  font-size: 2.5vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 2.5vw;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 0.98;
}
.navigationa-day {
  font-family: SourceHanSansCN-Normal;
  font-size: 1.563vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.563vw;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 0.98;
  padding-left: 4.167vw;
}
.navigationa-con {
  width: 100%;
  height: 55.926vh;
  padding: 1px 3.333vw 0px 4.167vw;
  box-sizing: border-box;
  margin-top: 7.13vh;
}
.navigationa-con-left {
  width: 29.5%;
  height: 100%;
}
.navigationa-con-left-top {
  width: 100%;
  height: 27.037vh;
  border-radius: 14px;
  background: url("../../assets/img/public/imgAa.png") no-repeat 100%;
  background-size: 100% 100%;
}
.navigationa-con-left-top-text {
  width: 100%;
  height: 10.098vh;
  font-family: AdobeHeitiStd-Regular;
  font-size: 1.875vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 10.098vh;
  letter-spacing: 0px;
  color: #fefefe;
  opacity: 0.98;
  padding-left: 1.406vw;
}
.navigationa-con-left-bottom {
  width: 100%;
  height: 27.037vh;
  margin-top: 1.759vh;
  border-radius: 14px;
  background: url("../../assets/img/public/imglist.png") no-repeat 100%;
}
.navigationa-con-left-bottom-item {
  width: 33%;
}
.navigationa-con-left-bottom-item-img {
  width: 3.073vw;
  display: block;
  margin: 0px auto;
}
.navigationa-con-left-bottom-item-text {
  font-family: AdobeHeitiStd-Regular;
  font-size: 1.592vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.592vw;
  color: #fefefe;
  opacity: 0.98;
  margin-top: 2.22vh;
  text-align: center;
}
.navigationa-con-left-bottom-item-shu {
  width: 0.313vw;
  height: 10vh;
}
.navigationa-con-listA {
  width: 19.375vw;
  height: 100%;
  border-radius: 14px;
  padding: 1.759vh 1.25vw;
  box-sizing: border-box;
  background: url("../../assets/img/public/imgB.png") no-repeat 100%;
}
.navigationa-con-list {
  width: 100%;
}
.navigationa-con-list-img {
  width: 2.5vw;
}
.navigationa-con-list-text {
  font-family: AdobeHeitiStd-Regular;
  font-size: 1.918vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 3.646vw;
  letter-spacing: 0vw;
  color: #ffffff;
  opacity: 0.98;
}
.navigationa-con-list-con {
  width: 100%;
  height: 50vh;
}
.navigationa-con-list-con-img {
  width: 8.75vw;
  opacity: 0.7;
}
.navigationa-con-listB {
  width: 19.375vw;
  height: 100%;
  border-radius: 14px;
  padding: 1.759vh 1.25vw;
  box-sizing: border-box;
  background: url("../../assets/img/public/imgC.png") no-repeat 100%;
}
.navigationa-con-listC {
  width: 19.375vw;
  height: 100%;
}
.navigationa-con-listC-topflex {
  width: 100%;
  padding: 1.759vh 1.25vw;
  box-sizing: border-box;
}
.navigationa-con-listC-top {
  border-radius: 14px;
  width: 19.375vw;
  height: 27.037vh;
  background: url("../../assets/img/public/imgD.png") no-repeat 100%;
}
.navigationa-con-listC-con {
  height: 17vh;
  width: 100%;
}
.navigationa-con-listC-bottom {
  border-radius: 14px;
  width: 19.375vw;
  height: 27.037vh;
  background: url("../../assets/img/public/imgE.png") no-repeat 100%;
  margin-top: 1.759vh;
}
.navigationa-con-list-con-imgB {
  width: 5.417vw;
  opacity: 0.7;
}
.navigationa-name {
  width: 100%;
  position: fixed;
  left: 0px;
  bottom: 5.741vh;
  font-family: AdobeHeitiStd-Regular;
  font-size: 2.2vw;
  font-weight: normal;
  font-stretch: normal;
  line-height: 1.875vw;
  letter-spacing: -0.021vw;
  color: #fefefe;
  opacity: 0.98;
  text-align: center;
}

//指针-可点
.cursor-pointer{
  cursor: pointer;
}

//不可点状态-半透明
.text-gray{
  opacity: 0.3;
}

//背景图变灰
.img-gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>
